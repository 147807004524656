export default [
  {
    title: 'Atendimentos Condutor',
    route: 'list-condutor-index',
    icon: 'GridIcon',
    perfil_id: [20, 999],
  },
  {
    title: 'Checklist',
    route: 'condutor-checklist',
    icon: 'GridIcon',
    perfil_id: [20, 999],
  },
]
